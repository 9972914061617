import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';

@Component({
    selector: 'app-mail-view',
    templateUrl: './mail-view.component.html',
    styleUrl: './mail-view.component.scss'
})
export class MailViewComponent implements OnInit {
    unsubscriber$: Subject<any> = new Subject();

    emailsAllowed: string[] = [];
    newEmail: string = "";
    loadingEmails: boolean = true;

    constructor(
        private activeRoute: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private adminService: AdminService
    ) { }

    ngOnInit(): void {
        this.loadingEmails = true;

        this.activeRoute.paramMap.pipe(takeUntil(this.unsubscriber$)).subscribe((params: ParamMap) => {
            this.adminService.getMailsInfo().subscribe((result: any) => {
                this.emailsAllowed =  result["values"];
                this.loadingEmails = false;
            }) 
        })
    }

    addAllowedEmail() {
        if(this.newEmail != "") {
            this.adminService.addAllowedEmail(this.newEmail, false).subscribe({
                next: (result: any) => {
                    this.emailsAllowed.push(this.newEmail);
                    this.newEmail = ""; 
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Email added successfully.' });
                },
                error: (error: any) => {                    
                    let errorMessage = 'Something went wrong. Try again.';
                    
                    if (error.status === 404) errorMessage = 'The requested email was not found.';
                    else if(error['error']['error']['message'].includes('Email already exists')) errorMessage = 'The email already exists in the allowed list.';
                    
                    this.messageService.add({ severity: 'error', summary: 'Rejected', detail: errorMessage });
                }
            }) 
        }
    }

    removeAllowedEmail(email: string) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.adminService.removeAllowedEmail(email, false).subscribe(
                    {
                        complete: () => {
                            this.emailsAllowed = this.emailsAllowed.filter((allowedEmail) => allowedEmail != email);
                            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'User removed from allowed emails list' });
                        },
                        error: () => {
                            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Something went wrong. Try again.' });
                        }
                    }
                ) 
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                }
            }
        })
    }

}


<div *ngIf="!loadingEmails">
    <p-card>
        <h2 style="color: #e88900 !important;"><b style="color: #e88900 !important;">{{ "Allowed Emails to send email" | translate }}</b></h2>
        <ul class="info-list">
            <li *ngFor="let email of emailsAllowed">
                <div class="email-list">
                    {{ email }}
                    <p-button (click)="removeAllowedEmail(email)" class="delete-button" label="Delete" styleClass="p-button-danger"></p-button>
                </div>
            </li>
            <hr>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    {{ "Allow Email" | translate }}
                </span>
                <input type="text" pInputText placeholder="Email" [(ngModel)]="newEmail"/>
                <button class="add-email-button" (click)="addAllowedEmail()" type="button" pButton icon="pi pi-plus-circle"></button>
    
                <p-toast></p-toast>
                <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
            </div>
        </ul>
    </p-card>
</div>
export const environment = {
	production: false,
	apiUrl: '/api/',
	b2cUrl: '/b2c/v1',
	apiPlenion: '/api/v1',
	apiPlenionKey: 'AIzaSyAe-Gkyk_NQiXPGe-rMAPZBy19EbaecDdg',
	apiUrlHost: 'https://dev-api-services.plenion247.eu',
	portalUrl: 'https://admin.dev-app.plenion247.eu',
	version: '1.8.7',
	firebase: {
		apiKey: 'AIzaSyBhvyWGz1PnCl-12IkXBQ9dU2ejBkuliWk',
		authDomain: 'dev-plenion-portal.firebaseapp.com',
		databaseURL: 'https://dev-plenion-portal.firebaseio.com',
		projectId: 'dev-plenion-portal',
		storageBucket: 'dev-plenion-portal.appspot.com',
		messagingSenderId: '268340926695',
		appId: '1:268340926695:web:75d6ebc9244035c4452a42',
	},
};
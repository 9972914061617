import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { AdminService } from 'src/app/services/admin.service';
import { MainContentService } from 'src/app/services/main-content.service';

@Component({
    selector: 'app-create-user-view',
    templateUrl: './create-user-view.component.html',
    styleUrls: ['./create-user-view.component.scss'],
})
export class CreateUserViewComponent {
    api!: boolean;
    portal!: boolean;
    admin!: boolean;
    creationMode: string = "generic";
    usersAddList: any[] = [];
    userOptions: any[] = [];
    selectedUser: string = '';
    userID: string = '';
    email: string = '';
    password: string = '';

    constructor(
        private router: Router,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private adminService: AdminService,
        private mainContentService: MainContentService
    ) {
        setTimeout(() => {this.api = false; this.portal = false; this.admin = false;}, 0);
    }

    ngOnInit(): void {
        this.adminService.getAddUsersList().subscribe((result: any) => {
            this.usersAddList = result;
            this.userOptions = Object.keys(this.usersAddList).map(key => ({ label: key, value: key }));
        })
    }

    addNewUser(event: Event) {
        event.preventDefault();

        let userID: any = null;
        let email: any = null;
        let passwd: any = null;
        
        if(this.creationMode === "generic") {
            var myFields = document.getElementById("fields-form-generic") as HTMLFormElement;
            
            if((myFields.elements.namedItem("userid") as HTMLInputElement).value)
                userID = (myFields.elements.namedItem("userid") as HTMLInputElement).value;
    
            if((myFields.elements.namedItem("email") as HTMLInputElement).value)
                email = (myFields.elements.namedItem("email") as HTMLInputElement).value;
               
            if((myFields.elements.namedItem("password") as HTMLInputElement).value)
                passwd = (myFields.elements.namedItem("password") as HTMLInputElement).value;

            if(userID === null || email === null || passwd === null) {
                this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Missing fields. Please fill all of them to create a generic user.' });
                return;
            }
        } else {
            email = this.selectedUser;
            userID = this.usersAddList[email];
            if(userID === null || email === '') {
                this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Missing fields. Please select a email to create a new user.' });
                return;
            }
        }
        
        this.confirmationService.confirm({
            message: 'Are you sure that you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.adminService.addUser(userID, email, passwd, this.api, this.portal, this.admin).subscribe(
                    {
                        complete: () => {
                            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'User created succesfully' });

                            setTimeout( () => this.router.navigate([`users`]), 1500);
                        },
                        error: (errorContent: any) => {
                            let errorMessage = 'Something went wrong. Try again.';

                            if (errorContent.status === 400 && errorContent.error.error.message) {
                                errorMessage = errorContent.error.error.message;
                            }
                            
                            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: errorMessage });
                        }
                    }
                )
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
                        break;
                    case ConfirmEventType.CANCEL:
                        this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
                        break;
                }
            }
        });
    }

    changeCreationMode(mode: string) {
        this.creationMode = mode;        
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginAuthGuard } from './core/auth/login.auth-guard';
import { LoginComponent } from './core/login/login.component';
import { LoginCustomerComponent } from './core/login-customer/login-customer.component';
import { UsersViewComponent } from './core/main-content/components/users-view/users-view.component';
import { ContactsViewComponent } from './core/main-content/components/contacts-view/contacts-view.component';
import { EditUserViewComponent } from './core/main-content/components/edit-user-view/edit-user-view.component';
import { CreateUserViewComponent } from './core/main-content/components/create-user-view/create-user-view.component';
import { TypesViewComponent } from './core/main-content/components/types-view/types-view.component';
import { InvoicesViewComponent } from './core/main-content/components/invoices-view/invoices-view.component';
import { TouchViewComponent } from './core/main-content/components/touch-view/touch-view.component';
import { CategoriesViewComponent } from './core/main-content/components/categories-view/categories-view.component';
import { FoldersViewComponent } from './core/main-content/components/folders-view/folders-view.component';
import { DownloadViewComponent } from './core/main-content/components/download-view/download-view.component';
import { LogDownloadViewComponent } from './core/main-content/components/log-download-view/log-download-view.component';
import { SupportDomainViewComponent } from './core/support-domain-view/support-domain-view.component'; 
import { RoutesViewComponent } from './core/main-content/components/routes-view/routes-view.component';
import { MailViewComponent } from './core/main-content/components/mail-view/mail-view.component';

const PUBLIC_APP_ROUTES: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'customerLogin', component: LoginCustomerComponent },
];

const LOGIN_REQUIRED_APP_ROUTES: Routes = [
    { path: 'routes', component: RoutesViewComponent },
    { path: 'logDownload', component: LogDownloadViewComponent },
    { path: 'download', component: DownloadViewComponent },
    { path: 'folders', component: FoldersViewComponent },
    { path: 'documentslabels', component: CategoriesViewComponent },
    { path: 'touch', component: TouchViewComponent },
    { path: 'invoices', component: InvoicesViewComponent },
    { path: 'portal', component: TypesViewComponent},
    { path: 'contacts', component: ContactsViewComponent},
    { path: 'users', component: UsersViewComponent},
    { path: 'users/editUser/:idUser', component: EditUserViewComponent},
    { path: 'users/adduser', component: CreateUserViewComponent },
    { path: 'support', component: SupportDomainViewComponent },
    { path: 'mail', component: MailViewComponent },
    { path: '', pathMatch: 'full', redirectTo: 'users' },
];

export const APP_ROUTES: Routes = [
    ...PUBLIC_APP_ROUTES,
    {
        path: '',
        canActivate: [LoginAuthGuard],
        children: LOGIN_REQUIRED_APP_ROUTES,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(APP_ROUTES)],
    providers: [LoginAuthGuard],
    exports: [RouterModule],
})
export class AppRoutingModule {}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/core/auth/auth-service.service';
import { CommonService } from 'src/app/services/common.service';
import { GooglePickerService } from 'src/app/shared/google-picker/google-picker.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    animations: [
		trigger('expand', [
			state(
				'hide',
				style({
					maxHeight: '0px',
					padding: '0px 20px',
				}),
			),
			state(
				'show',
				style({
					maxHeight: '151px',
					padding: '10px 20px',
				}),
			),
			transition('hide => show', [animate('0.5s ease')]),
			transition('show => hide', [animate('0.5s ease')]),
		]),
	],
})
export class NavbarComponent {
    menuItems: MenuItem[] = [];
    companyName: string = ""
    language: string = 'nl';
    selected: string = 'NL';

        constructor(
            private authService: AuthService, 
            private commonService: CommonService, 
            private cookieService: CookieService,
            private translate: TranslateService,
            private googlePickerService: GooglePickerService,
        ) {
            this.language = localStorage.getItem('language') ?? 'nl';
    		this.changeLanguage(this.language);
        }

    ngOnInit() {
        this.companyName = this.commonService.companyName;
        if (!window.location.href.includes('support')) {
            this.menuItems = this.getMenuItems();
        }else {
            this.menuItems = this.getSupportMenuItems();
        }
    }

    onLogout = () => {
        this.cookieService.deleteAll();
		this.authService.logout();
	};

    selectLanguageHandler(event: any) {}

	changeLanguage(language: string) {
		switch (language) {
			case 'en':
				this.selected = 'EN';
				this.language = 'en';
				break;
			case 'fr':
				this.selected = 'FR';
				this.language = 'fr';
				break;
			case 'nl':
				this.selected = 'NL';
				this.language = 'nl';
				break;
			default:
				this.selected = 'NL';
				this.language = 'nl';
		}
	}

    onChangeLanguage(language: string) {
		this.translate.use(language);
		localStorage.setItem('language', language);
		this.changeLanguage(language);
		this.googlePickerService.getLanguageForPicker(this.language);
	}

    getMenuItems() {
        return  [
            {
                label: 'Users',
                styleClass: 'menucus',
                routerLink: ['/users']
                // icon: 'pi pi-fw pi-user',
            },
            {
                label: 'Contacts',
                styleClass: 'menucus',
                routerLink: ['/contacts']
                // icon: 'pi pi-fw pi-mobile',
            },
            {
                label: 'Portal',
                styleClass: 'menucus',
                routerLink: ['/portal']
                // icon: 'pi pi-fw pi-mobile',
            },
            {
                label: 'Mail',
                styleClass: 'menucus',
                routerLink: ['/mail']
            },
            {
                label: 'Invoices',
                styleClass: 'menucus',
                routerLink: ['/invoices']
                // icon: 'pi pi-fw pi-mobile',
            },
            {
                label: 'Routes',
                styleClass: 'menucus',
                routerLink: ['/routes']
                // icon: 'pi pi-fw pi-mobile',
            },
            {
                label: 'Touch',
                styleClass: 'menucus',
                routerLink: ['/touch']
                // icon: 'pi pi-fw pi-mobile',
            },
            {
                label: 'Document Labels',
                styleClass: 'menucus',
                routerLink: ['/documentslabels']
                // icon: 'pi pi-fw pi-mobile',
            }
            ,
            {
                label: 'Folders',
                styleClass: 'menucus',
                routerLink: ['/folders']
                // icon: 'pi pi-fw pi-mobile',
            }        
            ,
            {
                label: "Download",
                styleClass: 'menucus',
                routerLink: ['/download']
            }
            ,
            {
                label: "Log Download",
                styleClass: 'menucus',
                routerLink: ['/logDownload']
            }
        ];
    }

    getSupportMenuItems() {
        return  [
            {
                label: 'Domains',
                styleClass: 'menucus',
                routerLink: ['/support']
                // icon: 'pi pi-fw pi-user',
            }
        ];
    }
}
